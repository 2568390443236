<template>
    <!-- <div class='daiban'> -->
    <div class="bottom">
        <div class="dispose">
            <div :class="[messageStatus === 0 ? 'no-dispose' : 'yet-dispose', 'button', 'flex-align-center', 'cponter']"
                @click="messageClick(0)">
                未读
            </div>
            <div :class="[messageStatus === 1 ? 'no-dispose' : 'yet-dispose', 'button', 'flex-align-center', 'cponter']"
                @click="messageClick(1)">
                已读
            </div>
            <div class="yidu cponter">全部标为已读</div>
        </div>
        <div class="contain">
            <div class="message flex-align-bet">
                <div class="ms-left"></div>
                <div class="ms-right">
                    <div class="ms-top flex-align-bet">
                        <div class="left">
                            <span>李逍遥33</span>
                        </div>
                        <div class="time">今天12:00</div>
                        <div class="btn flex-align-center">标为已读</div>
                    </div>
                    <div class="ms-bottom">考核「 加减分考核」</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "daiban",
    data() {
        return {
            messageStatus: 0,
        }
    },
    props: {

    },
    computed: {

    },
    methods: {
        messageClick(val) {
            this.messageStatus = val
        },
    }
}
</script>

<style scoped lang='scss'>
.bottom {
    height: calc(100% - 54px);
    // margin-top: 20px;
    padding: 20px 24px;

    .dispose {
        height: 5%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .no-dispose {
            background: rgba(20, 96, 250, 0.2);
            color: #1460FA;
        }

        .button {
            width: 4.6875rem;
            height: 2rem;
            border-radius: 10.5rem;
            font-size: .875rem;
            font-family: Source Han Sans-Regular;
            font-weight: 400;
            line-height: 1.25rem;
            margin-right: 1rem;
        }

        .yet-dispose {
            background: #F3F3F3;
            color: #333333;
        }

        .yidu {
            position: absolute;
            font-size: 14px;
            font-family: Source Han Sans-Regular;
            font-weight: 400;
            color: #1460FA;
            line-height: 20px;
            right: 24px;
        }
    }

    .contain {
        height: 95%;
        margin-top: 10px;

        .no-message {
            width: 100%;

        }

        .message {
            height: calc(100% / 12);

            .ms-left {

                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #34BC88;
                margin-right: 16px;
            }

            .ms-right {
                width: calc(100% - 56px);
                height: 100%;

                .ms-top {
                    height: 50%;

                    .left {
                        & span:nth-child(1) {
                            font-size: 14px;
                            font-family: Source Han Sans-Regular;
                            font-weight: 400;
                            color: #3D3D3D;
                            // color: red;
                            line-height: 20px;
                        }
                    }
                }

                .ms-bottom {
                    font-size: 14px;
                    font-family: Source Han Sans-Medium;
                    font-weight: 500;
                    color: #3D3D3D;
                    line-height: 20px;
                }
            }

            .time {
                display: block;
                font-size: 14px;
                font-family: Source Han Sans-Regular;
                font-weight: 400;
                color: #9298A4;
                line-height: 20px;
                display: block;
            }

            .btn {
                font-size: 14px;
                font-family: Source Han Sans-Regular;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;
                width: 77px;
                height: 28px;
                background: #2B80FF;
                border-radius: 139px 139px 139px 139px;
                display: none;

            }

            &:hover .btn {
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:hover .time {
                display: none;
            }
        }
    }
}
</style>