import dayjs from 'dayjs'

// 两位小数格式化
function numFilter(value, ele) {
  let tempValue = value
  if (value) {
    tempValue = value.toFixed(ele)
  }
  return tempValue
}
const formatDate = (time, module = 'YYYY-MM-DD') => {
  return time ? dayjs(new Date(time)).format(module) : time;
}

export default {
  formatDate,
  numFilter
}
