<template>
    <div class='editPassword'>
        <el-dialog class="xiugai" :visible.sync="dialogGo" :before-close="handleClose">
            <div class="edit-top">
                <img src="@/assets/login/broadcase.png" />
            </div>
            <div class="edit-bottom flex-align-center">
                <div class="top  flex-align-center">
                    <img src="@/assets/login/cry.png" />
                    <div class="title">您需要修改密码哦！</div>
                </div>
                <div class="center">
                    <div class="title">您需要修改密码后才能继续使用我们的系统</div>
                </div>
                <div class="bottom flex-align-center cponter" @click="editPassword">修改密码</div>

            </div>
            <div class="bottomquit cponter" @click="outLogin">退出登录</div>
        </el-dialog>
        <el-dialog class="queren" :visible.sync="dialogEdit" :before-close="handleClose">
            <div class="pass-top">
                修改密码
            </div>
            <div class="pass-center ">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item label="输入手机号" prop="mobile">
                        <el-input v-model="ruleForm.mobile">
                            <span slot="prefix">
                                <img src="@/assets/login/account.png" class="login-icon1" />
                            </span></el-input>

                    </el-form-item>
                    <el-form-item label="设置密码" prop="password">
                        <el-input maxlength="20" show-word-limit v-model="ruleForm.password" show-password>
                            <span slot="prefix">
                                <img src="@/assets/login/password.png" class="login-icon2" />
                            </span></el-input>

                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirmPassword">
                        <el-input maxlength="20" show-word-limit v-model="ruleForm.confirmPassword" show-password>
                            <span slot="prefix">
                                <img src="@/assets/login/password.png" class="login-icon2" />
                            </span></el-input>

                    </el-form-item>
                    <div class="pass-bottom flex-align-center cponter" @click="submitEdit">确认修改</div>
                </el-form>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import { editPassword } from '@/api/login/login.js'
export default {
    name: "editPassword",

    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
                // password 是表单上绑定的字段
            } else if (value !== this.ruleForm.password) {
                callback(new Error('两次密码不一致，请重新输入'))
            } else {
                callback()
            }
        }
        return {
            // dialogGo: false,
            dialogEdit: false,
            rules: {
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                ],
                password: [
                    {
                        required: true,
                        pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/,
                        message: "密码需要大小写字母、数字、最低八位、最长20位",
                    },
                ],
                confirmPassword: [
                    {
                        required: true, validator: validatePass2, trigger: 'blur'
                    },
                ]
            },
            ruleForm: {
                mobile: '',
                password: '',
                confirmPassword: ''
            }
        }

    },
    props: {

    },
    computed: {
        dialogGo() {
            return this.$store.state.user.passwordTag || JSON.parse(localStorage.getItem('isFirstStatus')) === 1 ? false : true
        }
    },
    methods: {
        init() {
            this.dialogGo = true
        },
        handleClose() { },
        editPassword() {
            this.dialogGo = false
            this.dialogEdit = true
        },
        submitEdit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log(this.ruleForm.mobile, 'this.ruleForm');
                    editPassword(
                        {
                            mobile: this.ruleForm.mobile,
                            password: this.ruleForm.password,
                            confirmPassword: this.ruleForm.confirmPassword
                        }
                    ).then(res => {
                        console.log(res, '6*');
                        if (res.code === 0 && res.data) {
                            console.log('修改成功');
                            this.dialogEdit = false
                            this.$store.dispatch("LogOut").then(() => {
                                this.$router.push({ 'path': '/login' })
                            });
                        }
                    })
                    // alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        outLogin() {
            this.$store.dispatch("LogOut").then(() => {
                this.$router.push({ 'path': '/login' })
            });
        }
    },
    mounted() {
        // console.log(document.cookie, 'document.cookie=');
        // this.$store.state.user.passwordTag === 0 ? this.dialogGo = true : this.dialogGo = false // 判断是否第一次登录 
    }
}
</script>

<style scoped lang='scss'>
.editPassword {
    ::v-deep .xiugai {
        .el-dialog {
            width: 440px;
            height: 440px;
            border-radius: 12px 12px 12px 12px;

            .el-dialog__header {
                display: none;
            }

            .el-dialog__body {
                padding: 0px;

                .edit-top {
                    height: 48%;

                    img {
                        height: 100%;
                        width: 100%;
                        background-size: 100%;
                    }
                }

                .edit-bottom {
                    flex-direction: column;

                    .top {
                        height: 10%;

                        .title {
                            font-size: 24px;
                            font-family: Source Han Sans-Medium;
                            font-weight: 500;
                            color: #3D3D3D;
                            line-height: 35px;
                        }

                        margin-bottom: 16px;
                    }

                    .center {
                        height: 20%;
                        width: 36%;
                        text-align: center;
                        margin-bottom: 39px;

                        .title {
                            font-size: 14px;
                            font-family: Source Han Sans-Regular;
                            font-weight: 400;
                            color: #767676;
                            line-height: 20px;
                        }
                    }

                    .bottom {
                        margin-bottom: 6px;
                        height: 10%;
                        font-size: 16px;
                        width: 344px;
                        height: 44px;
                        font-family: Source Han Sans-Medium;
                        font-weight: 500;
                        color: #FFFFFF;
                        background: linear-gradient(350deg, #1460FA 0%, #20A2FF 100%);
                        border-radius: 175px 175px 175px 175px;
                    }
                }

                .bottomquit {
                    left: 338px;
                    position: relative;
                    font-size: 14px;
                    font-family: Source Han Sans-Regular;
                    font-weight: 400;
                    color: #1460FA;
                    line-height: 20px;
                }
            }
        }
    }

    ::v-deep .queren {
        .el-dialog {
            width: 440px;
            height: 440px;
            padding: 23px 40px;
            border-radius: 12px 12px 12px 12px;

            .el-dialog__header {
                display: none;
            }

            .el-dialog__body {
                padding: 0px;

                .pass-top {
                    font-size: 24px;
                    font-family: PingFang SC-Semibold;
                    font-weight: 600;
                    color: #3D3D3D;
                    line-height: 34px;
                    margin-bottom: 26px;
                }

                .pass-center {
                    .el-form {

                        // border: 1px solid red;
                        .el-form-item {
                            .el-form-item__label {
                                width: 100% !important;
                                display: flex;
                                justify-content: start;
                                font-size: 14px;
                                font-family: Source Han Sans-Medium;
                                font-weight: 500;
                                color: #3D3D3D;
                                line-height: 20px;
                                margin-bottom: 8px;
                            }

                            .el-form-item__content {
                                margin-left: 0px !important;

                                .el-input {
                                    .el-input__prefix {
                                        display: flex;
                                        align-items: center;
                                        padding-left: 16px;

                                        img {
                                            display: flex;
                                        }
                                    }

                                    .el-input__inner {
                                        width: 100%;
                                        height: 40px;
                                        padding-left: 50px;
                                        background: #FFFFFF;
                                        border-radius: 43px 43px 43px 43px;
                                        opacity: 1;
                                        border: 1px solid #D0D3D6;
                                    }

                                }
                            }
                        }

                        .pass-bottom {
                            width: 360px;
                            height: 44px;
                            background: linear-gradient(350deg, #1460FA 0%, #20A2FF 100%);
                            border-radius: 34px 34px 34px 34px;
                            font-size: 16px;
                            font-family: Source Han Sans-Medium;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }
                }


            }
        }
    }

}
</style>