import { login, logout } from '@/api/login/login.js'
// import { getUserCache } from "@/api/system/user";
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: {},
    roles: [],
    permissions: [],
    limits: {},
    passwordTag: 0,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_LIMITS: (state, limits) => {
      state.limits = limits
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_EDITPASSWORD: (state, passwordTag) => {
      state.passwordTag = passwordTag
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      // const code = userInfo.code
      // const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        // login(username, password, code, uuid)

        login(username, password)
          .then((res) => {
            setToken(res.data.token)
            commit('SET_TOKEN', res.data.token)
            commit('SET_AVATAR', res.data)
            commit('SET_EDITPASSWORD', res.data.hasChangePassword)
            localStorage.setItem('isFirstStatus', JSON.stringify(res.data.hasChangePassword))
            localStorage.setItem('userDta', JSON.stringify(res.data))
            localStorage.setItem('isLogin', 1) // 代表已经登录过
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    // GetInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo()
    //       .then((res) => {
    //         const user = res.data.user
    //         const avatar = user.avatar == '' || user.avatar == null ? require('@/assets/images/profile.jpg') : user.avatar
    //         if (res.data.roles && res.data.roles.length > 0) {
    //           // 验证返回的roles是否是一个非空数组
    //           commit('SET_ROLES', res.data.roles)
    //           commit('SET_PERMISSIONS', res.data.permissions)
    //         } else {
    //           commit('SET_ROLES', ['ROLE_DEFAULT'])
    //         }
    //         commit('SET_NAME', user.userName)
    //         commit('SET_AVATAR', avatar)
    //         // getUserCache().then((res) => {
    //         //   commit("SET_LIMITS", res.data || {});
    //         // });
    //         resolve(res)
    //       })
    //       .catch((error) => {
    //         reject(error)
    //       })
    //   })
    // },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_LIMITS', {})
            localStorage.clear()
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    // FedLogOut({ commit }) {
    //   return new Promise((resolve) => {
    //     commit('SET_TOKEN', '')
    //     removeToken()
    //     resolve()
    //   })
    // },
  },
}

export default user
