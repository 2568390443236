import request from '@/config/http.js'
// 登录方法
export function login(username, password) {
  const data = {
    key: username,
    password: password,
  }
  return request({
    url: '/oa/staff/login',
    headers: {
      isToken: false,
    },
    method: 'post',
    data: data,
  })
}
// 退出方法
export function logout() {
  return request({
    url: '/oa/staff/logout',
    method: 'post',
  })
}
export function editPassword(data) {
  return request({
    url: '/oa/staff/changePassword',
    method: 'post',
    data: data,
  })
}
// 注册方法
// export function register(data) { //
//   return request({
//     url: "/register",
//     headers: {
//       isToken: false,
//     },
//     method: "post",
//     data: data,
//   });
// }
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/oa/staff/getUserInfo',
    method: 'get',
  })
}

// 获取验证码
// export function getCodeImg() {
//   return request({
//     url: "/captchaImage",
//     headers: {
//       isToken: false,
//     },
//     method: "get",
//     timeout: 20000,
//   });
// }
