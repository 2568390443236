<template>
    <!-- <div class='daiban'> -->
    <div class="bottom">
        <div class="dispose">
            <div :class="[messageStatus === 0 ? 'no-dispose' : 'yet-dispose', 'button', 'flex-align-center', 'cponter']"
                @click="messageClick(0)">
                未处理
            </div>
            <div :class="[messageStatus === 1 ? 'no-dispose' : 'yet-dispose', 'button', 'flex-align-center', 'cponter']"
                @click="messageClick(1)">
                已处理
            </div>
        </div>
        <div class="contain">
            <div class="message flex-align-bet">
                <div class="ms-left"></div>
                <div class="ms-right">
                    <div class="ms-top flex-align-bet">
                        <div class="left">
                            <span>李逍遥</span>
                            <span>您好，邀请你完成</span>
                        </div>
                        <div class="right">今天12:00</div>
                    </div>
                    <div class="ms-bottom">考核「 加减分考核」</div>
                </div>
            </div>
            <!-- <div class="no-message flex-align-center">
						<img src="@/assets/header/no-message.png" />
					</div> -->
        </div>
    </div>
    <!-- </div> -->
</template>

<script>

export default {
    name: "daiban",
    data() {
        return {
            messageStatus: 0,
        }
    },
    props: {

    },
    computed: {

    },
    methods: {
        messageClick(val) {
            this.messageStatus = val
        },
    }
}
</script>

<style scoped lang='scss'>
.bottom {
    height: calc(100% - 54px);
    // margin-top: 20px;
    padding: 20px 24px;

    .dispose {
        height: 5%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .no-dispose {
            background: rgba(20, 96, 250, 0.2);
            color: #1460FA;
        }

        .button {
            width: 4.6875rem;
            height: 2rem;
            border-radius: 10.5rem;
            font-size: .875rem;
            font-family: Source Han Sans-Regular;
            font-weight: 400;
            line-height: 1.25rem;
            margin-right: 1rem;
        }

        .yet-dispose {
            background: #F3F3F3;
            color: #333333;
        }
    }

    .contain {
        height: 95%;
        margin-top: 10px;

        .no-message {
            width: 100%;

        }

        .message {
            height: calc(100% / 12);

            .ms-left {

                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #34BC88;
                margin-right: 16px;
            }

            .ms-right {
                width: calc(100% - 56px);

                .ms-top {
                    .left {
                        & span:nth-child(1) {
                            font-size: 14px;
                            font-family: Source Han Sans-Regular;
                            font-weight: 400;
                            color: #3D3D3D;
                            // color: red;
                            line-height: 20px;
                        }

                        & span:nth-child(2) {
                            margin-left: 16px;
                            font-size: 14px;
                            font-family: Source Han Sans-Regular;
                            font-weight: 400;
                            color: #9298A4;
                            line-height: 20px;
                        }
                    }

                    .right {
                        font-size: 14px;
                        font-family: Source Han Sans-Regular;
                        font-weight: 400;
                        color: #9298A4;
                        line-height: 20px;
                    }
                }

                .ms-bottom {
                    font-size: 14px;
                    font-family: Source Han Sans-Medium;
                    font-weight: 500;
                    color: #3D3D3D;
                    line-height: 20px;
                }
            }
        }
    }
}
</style>