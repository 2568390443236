import { HOST } from './hostConfig'
import Axios from 'axios'
import { getToken } from '@/utils/auth'
import cache from '@/plugin/cache.js'
import { tansParams } from '@/utils/enum'
import store from '@/store/index'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import router from '@/router'
const service = Axios.create({
  baseURL: HOST, // api 的 base_url
  // timeout: 5000, // 请求超时时间
  // 'withCredentials': true
})
export let isRelogin = { show: false }
// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false // true
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false //false
    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // config.url = 'http://www.wutongshop.com' + config.url;

    // get请求映射params参数
    // if (config.method === 'get' && config.params) {
    //   console.log('222')
    //   let url = config.url + '?' + tansParams(config.params)
    //   // url = url.slice(0, -1)
    //   config.params = {}
    //   config.url = url
    // }
    // if (config.method === 'get' && config.params) {
    //   let url = config.url + '?' + tansParams(config.params)
    //   // url = url.slice(0, -1)
    //   config.params = {}
    //   config.url = url
    // }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime(),
      }
      // const sessionObj = cache.session.getJSON('sessionObj')
      // if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      //   cache.session.setJSON('sessionObj', requestObj)
      // } else {
      //   const s_url = sessionObj.url // 请求地址
      //   const s_data = sessionObj.data // 请求数据
      //   const s_time = sessionObj.time // 请求时间
      //   const interval = 1000 // 间隔时间(ms)，小于此时间视为重复提交
      //   if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
      //     const message = '数据正在处理，请勿重复提交'
      //     console.warn(`[${s_url}]: ` + message)
      //     return Promise.reject(new Error(message))
      //   } else {
      //     cache.session.setJSON('sessionObj', requestObj)
      //   }
      // }
    }
    return config
  },
  (error) => {
    console.log(error)
    Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = res.data.msg
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    if (code === 1002) {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        localStorage.removeItem('isLogin')
        // location.href = '/login'
        router.push({ path: '/login' }) // 到登录页
      })
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      Message({
        message: msg,
        type: 'error',
      })
      return Promise.reject(new Error(msg))
      console.log(msg)
    } else if (code !== 200) {
        if(code === 5003) {
            Message({
                dangerouslyUseHTMLString: true,
                message: `<span>${msg}</span>`,
                type: 'error',
                showClose: true,
            });
        } else {
            Message.error(msg)
        }
        console.log(JSON.stringify(msg, '4444444====='))
        return Promise.reject('error')
    } else {
      return res.data
    }
  }
  // (error) => {
  //   console.log("err" + error);
  //   let { message } = error;
  //   if (message == "Network Error") {
  //     message = "后端接口连接异常";
  //   } else if (message.includes("timeout")) {
  //     message = "系统接口请求超时";
  //   } else if (message.includes("Request failed with status code")) {
  //     message = "系统接口" + message.substr(message.length - 3) + "异常";
  //   }
  //   Message({
  //     message: message,
  //     type: "error",
  //     duration: 5 * 1000,
  //     showClose: true,
  //   });
  //   return Promise.reject(error);
  // }
)
export default service
