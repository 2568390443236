<template>
<el-avatar v-bind="$props" :style="avatarStyle" :size="size">
    {{ name }}
</el-avatar>
</template>
<script>

const colors = [
    '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e',
    '#f1c40f', '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6'
];

export default {
    'name': 'OAAvatar',
    'props': {
        'name': String,
        'size': Number
    },
    'computed': {
        avatarStyle() {
            if (!this.name) return;
            const color = colors[this.name.charCodeAt(0) % colors.length];
            return {
                'backgroundColor': color
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>
