import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    hidden: true,
  },
  {
    path: '/login',
    component: () => import('@/views/pages/login/Index.vue'),
    hidden: true,
    meta: { title: '登录页面' },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/performanceManger', //绩效管理
        name: 'performanceManger',
        redirect: '/workbench',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/Index.vue'),
        children: [
          {
            path: '/workbench', //考核工作台
            name: 'workbench',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/workbench.vue'),
            meta: { id: 1, title: '考核工作台' },
          },
          {
            path: '/management', //考核管理
            name: 'management',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/management.vue'),
            meta: { id: 2, title: '考核管理' },
          },
          {
            path: '/managementDetail', //考核管理
            name: 'ManagementDetail',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/management/managementDetail.vue'),
            meta: { id: 2, title: '考核管理' },
          },
          {
            path: '/assessment', //考核模版
            name: 'assessment',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/assessment.vue'),
            meta: { id: 3, title: '考核模版' },
          },
          {
            path: '/business', //企业指标库
            name: 'business',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/business/business.vue'),
            meta: { id: 4, title: '企业指标库' },
          },
          {
            path: '/assessmentSet', //考核设置
            name: 'AssessmentSet',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/assessmentSet/assessmentSet.vue'),
            meta: { id: 5, title: '考核设置' },
          },
          {
            path: '/indicator', //考核指标库
            name: 'indicator',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/indicator.vue'),
            meta: { id: 6, title: '考核指标库' },
          },
          {
            path: '/help', //考核帮助
            name: 'help',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/help.vue'),
            meta: { id: 7, title: '考核帮助' },
          },
          {
            path: '/regular', //评价规则
            name: 'regular',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/performanceManger/components/help.vue'),
            meta: { id: 8, title: '评价规则' },
          },
          {
            path: '/test',
            name: 'Test',
            component: () => import(/* webpackChunkName: "about" */ '../views/pages/test.vue'),
            meta: { id: 9, title: 'test' },
          },
        ],
      },
      {
        path: '/essentialIndex', // 考核模板
        name: 'essentialIndex',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/assessmentTemplate/Index.vue'),
      },
      {
        path: '/organization', //组织管理
        name: 'organization',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/organization/Index.vue'),
      },
      {
        path: '/myperformance', //我的绩效-我的考核
        name: 'myperformance',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/myPerformance/Index.vue'),
      },
      {
        path: '/myEvaluate', //我的绩效-我评价的
        name: 'myEvaluate',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/myPerformance/components/myEvaluate.vue'),
      },
      {
        path: '/assessWrite', //我的绩效
        name: 'assessWrite',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/assessWrite'),
      },
      {
        path: '/userMsg', //用户个人信息
        name: 'userMsg',
        component: () => import(/* webpackChunkName: "about" */ '../views/pages/userMsg/index.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}
const routerreplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return routerreplace.call(this, location).catch((error) => error)
}
export default router
