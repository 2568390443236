import Vue from 'vue'
import {
  Avatar,
  Collapse,
  CollapseItem,
  Pagination,
  Tree,
  // Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Switch,
  // Link,
  Popover,
  // Slider,
  // Progress,
  Badge,
  // Rate,
  // Steps,
  // Step,
  Drawer,
  Cascader,
  // Transfer,
  // InfiniteScroll,
  Backtop,
  Carousel,
  CarouselItem,
  Upload,
  Divider,
  ColorPicker,
  Scrollbar,
  Dialog,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Alert,
  Icon,
  Row,
  Col,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Image,
  Loading,
  MessageBox,
  Message,
  Card,
  Popconfirm
} from 'element-ui'

// 弹框的初始 z-index（默认值：2000）
Vue.prototype.$ELEMENT = { 'size': 'mini', 'zIndex': 3000 }

Vue.use(Scrollbar)
  .use(Avatar)
  .use(Backtop)
  .use(Carousel)
  .use(CarouselItem)
  .use(Collapse)
  .use(CollapseItem)
  .use(Cascader)
  .use(Popover)
  .use(Pagination)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Upload)
  .use(ColorPicker)
  .use(Loading)
  .use(Loading.directive)
  .use(Dialog)
  .use(Menu)
  .use(Submenu)
  .use(MenuItem)
  .use(MenuItemGroup)
  .use(Input)
  .use(InputNumber)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Checkbox)
  .use(CheckboxButton)
  .use(CheckboxGroup)
  .use(Select)
  .use(Option)
  .use(OptionGroup)
  .use(Button)
  .use(ButtonGroup)
  .use(Table)
  .use(TableColumn)
  .use(DatePicker)
  .use(TimeSelect)
  .use(TimePicker)
  .use(Tooltip)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Form)
  .use(FormItem)
  .use(Tabs)
  .use(TabPane)
  .use(Tag)
  .use(Alert)
  .use(Icon)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(Container)
  .use(Header)
  .use(Aside)
  .use(Main)
  .use(Footer)
  .use(Image)
  .use(Divider)
  .use(Switch)
  .use(Tree)
  .use(Drawer)
  .use(Popconfirm)
  .use(Badge)
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$loading = Loading.loading
Vue.prototype.$message = Message
