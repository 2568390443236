<template>
	<div class='name'>
		<Header />
		<editPassword></editPassword>
		<!-- <keep-alive> -->
			<router-view />
		<!-- </keep-alive> -->
	</div>
</template>

<script>
import editPassword from '@/views/pages/login/editPassword.vue'
import Header from '../../components/header/Index.vue'
export default {
	name: "Home",
	components: {
		Header,
		editPassword
	},
	data() {
		return {
		}
	},
	'computed': {
	},
	methods: {
		test() {

		}
	},
	mounted() {
	}
}
</script>

<style scoped lang='scss'>
.name {
	color: $font-3;
	height: 100%;
}
</style>
