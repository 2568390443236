<template>
	<div class='header' v-if='!hideHeader'>
		<div class='header-logo flex-align-bet'>
			<div class='logo-img cponter'>
				<img src='@assets/logo.png' alt=''>
			</div>
			<div class="title">中咨华科交通建设技术有限公司</div>
		</div>
		<div class="center">
			<div class="flower">
				<img src='@assets/header/home.png' alt=''>
			</div>
			<div class="line"></div>
			<div class='header-menu'>
				<div :class="[selectTitle === item.id ? 'select' : 'menu-item', 'cponter']" v-for='item in menuList'
					:key="item.id" @click='itemClick(item)'>
					{{ item.title }}
				</div>
			</div>
		</div>
		<div class="header-user">
			<div class="right flex-align-around">
				<div></div>
				<div class="information cponter">
					<img src="@/assets/header/bell.png" style="opacity: 0;"/>
				</div>

				<el-popover popper-class="useredit" placement="bottom" width="200" trigger="click">
					<div class="user cponter"></div>
					<el-avatar style="background:transparent" class="cponter" v-if="userDta.headImg" :size="28"
						slot="reference">
						<img :src="userDta.headImg" />
					</el-avatar>
					<el-avatar v-else :size="28" slot="reference" class="cponter"
						:style="{ background: color2[Math.round(Math.random() * 5)] }">
						{{ userDta.name ? userDta.name.substring(0, 1) : '' }}
					</el-avatar>
					<div class="logout cponter" @click="logout">退出登录</div>
					<!-- <div class="logout cponter" @click="userMsg">个人信息</div> -->
				</el-popover>
			</div>
		</div>
		<el-drawer :show-close="true" :withHeader="false" custom-class="message-pop" :visible.sync="drawer" direction="rtl">
			<div class="top">
				<el-badge v-for="(item, index) in drawerNotice" :key="index" :value="item.value"
					class="item flex-align-center">
					<div :class="[activeNoticeIndex == index ? 'active' : '', 'daiban', 'fon-style', 'flex-align-center', 'cponter']"
						@click="notice(index)">
						{{ item.name }}
					</div>
				</el-badge>
			</div>
			<Daiban v-if="activeNoticeIndex == 0"></Daiban>
			<Tongzhi v-if="activeNoticeIndex == 1"></Tongzhi>
		</el-drawer>
	</div>
</template>

<script>
import Daiban from './daiban.vue'
import Tongzhi from './tongzhi.vue'
export default {
	name: "index",
	components: {
		Daiban,
		Tongzhi
	},
	data() {
		return {
			menuList: [],
			selectTitle: 1,
			drawer: false,
			drawerNotice: [{ name: '待办', value: 3 }, { name: '通知', value: 3 }, { name: '@与回复', value: 3 }, { name: '提醒', value: 3 },],
			activeNoticeIndex: 0,
			userDta: {},
			color2: [
				'rgb(255, 240, 203)',
				'rgb(190, 244, 224)',
				'rgb(203, 204, 255)',
				'rgb(249, 203, 255)',
				'rgb(203, 217, 255)',
				'rgb(255, 230, 203)'
			]
		}
	},
	computed: {
		hideHeader() {
			return this.$route.query.hideHeader
		}
	},
	created() {
		let isAdmin = JSON.parse(localStorage.getItem('userDta'))
		console.log(isAdmin, 'isAdmin');
		if (isAdmin && isAdmin.isAdmin == 1) {
			this.menuList = [{ id: 1, key: 'performanceManger', title: '绩效管理', pathName: 'performanceManger' },
			{ id: 2, key: 'myperformance', title: '我的绩效', pathName: 'myperformance' },
			{ id: 3, key: 'organization', title: '组织管理', pathName: 'organization' }]
		} else {
			this.menuList = [
				{ id: 2, key: 'myperformance', title: '我的绩效', pathName: 'myperformance' },
			]
			this.selectTitle = 2;
		}

	},
	methods: {
		goHome() {
			this.$router.push({ name: 'performanceManger' })
		},
		itemClick(item) {
			this.selectTitle = item.id
			if (item.pathName) {
				this.$router.push({ name: item.pathName })
			}
		},
		async logout() {
			this.$confirm("确定注销并退出系统吗？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					localStorage.clear() // 清除所有
					this.$store.dispatch("LogOut").then(() => {
						this.$router.push({ path: '/login' })
					});
				})
				.catch(() => { });
		},
		messagePop() {
			this.drawer = true
		},
		handleClick() {

		},
		notice(val) {
			this.activeNoticeIndex = val
			console.log(this.activeNoticeIndex, 'this.activeNoticeIndex');
		},
		userMsg() {
			this.$router.push({ name: 'userMsg' })
		}
	},
	mounted() {
		this.userDta = JSON.parse(localStorage.getItem('userDta'))
		this.goHome()
		if (this.userDta.isAdmin !== 1) {
			this.$router.push({ name: 'myperformance' })
		}
	}
}
</script>

<style scoped lang='scss'>
.header {
	height: 4.375rem;
	background: linear-gradient(350deg, #0400F3 0%, #20A2FF 100%);
	border-radius: 0px 0px 1.5rem 1.5rem;
	display: flex;
	align-items: center;
	color: $bg-white;
	margin-bottom: .625rem;
	position: relative;
	width: 100%;

	.header-logo {
		width: 349px;
		padding-left: 3.75rem;

		margin-right: 6.25rem;

		.logo-img {
			width: 3.125rem;
			height: 3.125rem;
		}

		img {
			width: 100%;
			height: 100%;
		}

		.title {
			font-size: 18px;
			font-family: Alibaba PuHuiTi 2.0-75 SemiBold;
			font-weight: 600;
			color: #FFFFFF;
			line-height: 1.5625rem;
		}
	}

	.center {
		width: calc(80% - 12.5rem - 6.25rem);
		display: flex;
		align-items: center;
		justify-content: start;

		.flower {
			position: relative;
			margin-right: 1.25rem;

		}

		.line {
			background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #dfe3ea 49%, hsla(0, 0%, 100%, 0));
			border-radius: .0625rem;
			height: 1.875rem;
			min-width: .0625rem;
			width: .0625rem;
		}

		.header-menu {
			display: flex;
			align-items: center;
			font-size: $fz16;
			font-weight: 600;

			.menu-item {
				padding: 0 2rem;
			}

			.select {
				position: relative;
				padding: 0 2rem;
				scale: 1.3;

				&::after {
					background: linear-gradient(180deg, #fff, rgba(40, 121, 255, 0));
					content: "";
					display: inline-block;
					filter: blur(4px);
					height: .625rem;
					left: 50%;
					position: absolute;
					top: 65%;
					transform: translate(-50%);
					width: 1.875rem;
				}
			}

		}
	}


	.header-user {
		position: absolute;
		height: 100%;
		width: 12.5rem;
		right: 2.604167rem
			/* 500/192 */
		;

		.right {
			height: 100%;



			.user {
				width: 1.9375rem;
				height: 1.9375rem;
				background: #FFCC81;
				border-radius: 50%;
			}
		}
	}

	::v-deep .message-pop {
		.el-drawer__body {
			height: 100%;
			padding-left: .625rem;

			.top {
				height: 3.375rem;
				background: #FFFFFF;
				box-shadow: inset 0px -1px 0px 0px #EAECF0;
				opacity: 1;
				width: 100%;
				display: flex;

				.daiban,
				.tongzhi,
				.huifu,
				.tixing {
					width: 100%;
					color: #3D3D3D;
					height: 100%;
				}

				.active {
					color: #1460FA;
					border-bottom: 1px solid #1460FA;

				}

				.fon-style {
					font-size: 1rem;
					font-family: Source Han Sans-Regular;
					font-weight: 400;
					line-height: 1.4375rem;
				}
			}

			// .bottom {
			// 	height: calc(100% - 54px);
			// 	// margin-top: 20px;
			// 	padding: 20px 24px;

			// 	.dispose {
			// 		height: 5%;
			// 		display: flex;
			// 		justify-content: flex-start;
			// 		align-items: center;

			// 		.no-dispose {
			// 			background: rgba(20, 96, 250, 0.2);
			// 			color: #1460FA;
			// 		}

			// 		.button {
			// 			width: 4.6875rem;
			// 			height: 2rem;
			// 			border-radius: 10.5rem;
			// 			font-size: .875rem;
			// 			font-family: Source Han Sans-Regular;
			// 			font-weight: 400;
			// 			line-height: 1.25rem;
			// 			margin-right: 1rem;
			// 		}

			// 		.yet-dispose {
			// 			background: #F3F3F3;
			// 			color: #333333;
			// 		}
			// 	}

			// 	.contain {
			// 		height: 95%;
			// 		margin-top: 10px;

			// 		.no-message {
			// 			width: 100%;

			// 		}

			// 		.message {
			// 			height: calc(100% / 12);

			// 			.ms-left {

			// 				width: 40px;
			// 				height: 40px;
			// 				border-radius: 50%;
			// 				background: #34BC88;
			// 				margin-right: 16px;
			// 			}

			// 			.ms-right {
			// 				width: calc(100% - 56px);

			// 				.ms-top {
			// 					.left {
			// 						& span:nth-child(1) {
			// 							font-size: 14px;
			// 							font-family: Source Han Sans-Regular;
			// 							font-weight: 400;
			// 							color: #3D3D3D;
			// 							// color: red;
			// 							line-height: 20px;
			// 						}

			// 						& span:nth-child(2) {
			// 							margin-left: 16px;
			// 							font-size: 14px;
			// 							font-family: Source Han Sans-Regular;
			// 							font-weight: 400;
			// 							color: #9298A4;
			// 							line-height: 20px;
			// 						}
			// 					}

			// 					.right {
			// 						font-size: 14px;
			// 						font-family: Source Han Sans-Regular;
			// 						font-weight: 400;
			// 						color: #9298A4;
			// 						line-height: 20px;
			// 					}
			// 				}

			// 				.ms-bottom {
			// 					font-size: 14px;
			// 					font-family: Source Han Sans-Medium;
			// 					font-weight: 500;
			// 					color: #3D3D3D;
			// 					line-height: 20px;
			// 				}
			// 			}
			// 		}
			// 	}
			// }
		}
	}

	::v-deep .item {
		width: 20%;

		.el-badge__content {
			position: absolute;
			top: 14px;
			right: 10px;
			transform: translateY(0%) translateX(0%);
		}
	}
}
</style>
<style ></style>