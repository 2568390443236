let HOST = ''

// 判断当前环境
if (process.env.NODE_ENV === 'production') {
  // 生产环境
  HOST = '/'
} else {
  // 开发环境
  HOST = 'apis/'
}
module.exports = {
  HOST,
}
