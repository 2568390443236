import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import filters from './filters/index.js'
import commons from './utils/index.js'
import './plugin/element.js'
import '@/styles/reset.css'
import './plugin/element-variables.scss'
import './styles/index.scss'
import './fonts/iconfont.css'
import 'animate.css'
import './permission' // permission control
import draggable from 'vuedraggable'
import VueBus from 'vue-bus';
// import 'csshint'
import Avatar from '@/components/avatar';
import '@/assets/icon/style.css';

Vue.prototype.$commons = commons // 公共方法
Vue.prototype.$filters = filters // 过滤器
Vue.prototype.$store = store // vuex存储
Vue.use(VueBus);
Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]))
Vue.component('draggable', draggable)
Vue.config.productionTip = false

Vue.component('oa-avatar', Avatar);

new Vue({
  router,
  store,
  'render': (h) => h(App)
}).$mount('#app')
