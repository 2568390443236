const formatAssEle = (type) => {
	let icon = ''
	let txt = ''
	switch (+type) {
		case 1:
			icon = 'oa-icon-wenzi'
			txt = '文本'
			break;
		case 2:
			icon = 'oa-icon-wenzi'
			txt = '数值'
			break
	}
	return { icon, txt }
}
export  {
	formatAssEle
}