import Vue from 'vue'
import Vuex from 'vuex'

import DemoStore from './DemoStore.js'
import user from './user.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  modules: {
    DemoStore,
    user,
  },
})
