export default {
	namespaced: true,
	state: {
		count: 1
	},
	mutations: {
		SET_COUNT(state, count) {
			state.count = count
		}
	},
	actions: {
		countSet({ commit }, count) {
			commit('SET_COUNT', count)
		},
	},
	getters: {
		countGet: (state) => {
			return state.count
		}
	}
}